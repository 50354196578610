import React from "react"
import { StaticQuery, graphql } from "gatsby"

import styles from "./podcasts.module.scss"
import { PodcastCoverImage } from "../podcast-cover-image/podcast-cover-image"

export const Podcasts = () => (
  <StaticQuery
    query={graphql`
      {
        allOpmlPodcast {
          edges {
            node {
              id
              name
              description
              url
              image {
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <main>
        <article>
          <div>
            <div>
              <p>
                Here is a list of all of the podcasts that I'm listening on
                daily basis.
              </p>

              {data.allOpmlPodcast.edges
                .filter((edge) => edge.node.image)
                .map((edge) => edge.node)
                .map((podcast) => (
                  <div className={styles.podcastWrapper} key={podcast.id}>
                    <a
                      href={podcast.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PodcastCoverImage imageId={podcast.id} />
                      <h4>{podcast.name}</h4>
                    </a>
                    <p>{podcast.description}</p>
                    <hr />
                  </div>
                ))}
            </div>
          </div>
        </article>
      </main>
    )}
  />
)
