import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import styles from "./podcast-cover-image.module.scss"

export const PodcastCoverImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 400, quality: 50) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid.originalName === `${props.imageId}.png`
      )

      if (!image) {
        return null
      }

      return <Img fluid={image.node.fluid} className={styles.cover} />
    }}
  />
)
