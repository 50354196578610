import React from "react"

import Layout from "../components/layout/layout"
import { Podcasts } from "../components/podcasts/podcasts"

const IndexPage = () => (
  <Layout title="Podcasts">
    <Podcasts />
  </Layout>
)

export default IndexPage
